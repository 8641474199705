import { css } from '@emotion/react';
import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_horizontal_cross_center, flex, jc, } from '~/modules/AppLayout/FlexGridCss';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { AppLink } from '~/components/AppLink';
import styled from '@emotion/styled';
import { fontWeight600 } from '~/css/font';
import { BiLinkExternal } from 'react-icons/bi';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import { store } from '~/pages/heineken_template/_private/store';
// import { PocketBrokerOpenAccountLogo } from './PocketBrokerOpenAccountLogo'
// export const PocketBrokerEventLink = memo<ReactProps>(function PocketBrokerEventLink() {
//   return (
//     <a
//       href={'https://events.pocket.tw/12-29509'}
//       target='_blank'
//       rel='noreferrer'
//       css={css`
//         height: 38px;
//       `}
//     >
//       <img
//         src='lung88988/news.png'
//         css={css`
//           height: 100%;
//           border-radius: 2px;
//         `}
//       />
//     </a>
//   )
// })
export const Lung88988Stock_Topbar = memo(function Lung88988Stock_Topbar() {
    const { isPc, isPhone } = useMedia();
    const styleds = {
        Topbar: styled.div `
      ${fill_horizontal_all_center};
      ${jc.spaceBetween};
      background-color: #202020;
      border-bottom: 1px solid #777777;
      padding: 0px 8px;
    `,
        TopbarPhone: styled.div `
      ${fill_horizontal_cross_center};
      background-color: #202020;
      border-bottom: 1px solid #777777;
    `,
        TopbarTitle: styled.div `
      ${flex.h.allCenter}
      font-size: 22px;
      background: linear-gradient(170deg, #eeeeee, #bbbbbb);
      background-clip: text;
      color: transparent;
      ${fontWeight600}
    `,
        TopbarLogo: styled.img `
      max-width: 40px;
      max-height: 100%;
    `,
        TopbarComponents: styled.div `
      ${flex.h.allCenter}
      gap:4px;
    `,
    };
    return (<div>
      {!isPhone && (<styleds.Topbar>
          <styleds.TopbarTitle>
            <styleds.TopbarLogo src='lung88988/favicon1.png'/>
            {isPc && <span>SUPERSTOCK動能操盤系統-股票版</span>}
          </styleds.TopbarTitle>

          <styleds.TopbarComponents>
            {/* <PocketBrokerEventLink /> */}
            {/* <PocketBrokerOpenAccountLogo /> */}
            <Lung88988VersionSwitch url='https://lungfutures.moneycom.in/'>
              動能操盤系統-期貨版
            </Lung88988VersionSwitch>
            <ChartingServerSwitch charting={store.charting}/>
            <UserAvatarAsDialogButton />
          </styleds.TopbarComponents>
        </styleds.Topbar>)}
      {!isPc && (<styleds.TopbarPhone>
          <Preset_Topbar showLeftBurger showRightBurger componentsInRight={<div css={fill_horizontal_cross_center}>
                {/* <PocketBrokerOpenAccountLogo /> */}
                <Lung88988VersionSwitch url='https://lungfutures.moneycom.in/'>
                  期貨版
                </Lung88988VersionSwitch>
                <ChartingServerSwitch charting={store.charting} css={css `
                    width: 108px;
                  `}/>
                <UserAvatarAsDialogButton />
              </div>}/>
        </styleds.TopbarPhone>)}
    </div>);
});
export const Lung88988VersionSwitch = memo(function Lung88988VersionSwitch(props) {
    return (<styleds.Container>
        <BiLinkExternal />
        <styleds.Link>
          <AppLink href={props.url} target='_blank'>
            {props.children}
          </AppLink>
        </styleds.Link>
      </styleds.Container>);
});
const styleds = {
    Container: styled.div `
    ${flex.h.allCenter};
    height: 40px;
    background-color: #2a2a2a;
    border-radius: 5px;
    padding: 0px 8px;
    border: 1px solid #555555;
    gap: 8px;
    color: #eeeeee !important;
    white-space: nowrap;
    &:hover {
      background-color: #2f2f2f;
    }
  `,
    Link: styled.div `
    * {
      ${fill_horizontal_all_center};
      color: #eeeeee !important;
    }
  `,
};
